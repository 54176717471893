import React from "react";
import Layout from "../components/layout-contents";
import Container from "../components/container";
import {graphql} from "gatsby";

const Logo = () => (
  
  <div style={{ marginBottom: "50px", textAlign: "center"}}>
      <img
        src="/images/distro/distro_logo.png"
        width= "75%"
        alt=""
      />
     <p style={{textAlign: "left"}}>All orders include COdA fanzine. Swaps are welcome on selected items!<br />
     For more info & shipping prices contact <a href="mailto:info@codafanzine.com">info@codafanzine.net</a></p>
  </div>
);

const Distro = (props) => (
  <div style={{display: "flex", justifyContent: "space-between"}}>
    <p><a href={props.link} target="_blank" rel="noreferrer" > <b>{props.title}</b></a> - {props.sub} <br /><i>{props.excerpt}</i></p> <p style={{marginLeft: "1px"}}>{props.price}</p>
  </div>
);

export default ({ data }) => (
  <Layout>
    <Container>
    <h1 className='page_title'>
      <img    className='page_title_image'
              src="/images/home/logo_distro.png"
              alt="distro"
            /></h1>
      <Logo></Logo>
      {data.allDistroJson.edges.map(({ node }) => (
        <Distro title={node.title}
        sub={node.sub}
        excerpt={node.excerpt} 
        price={node.price} 
        link={node.link}
        />
      ))}
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    allDistroJson {
      edges {
        node {
          title
          sub
          excerpt
          price
          link
        }
      }
    }
  }
`;
